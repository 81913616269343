import { apiConfig } from '../Configs/apiConfig'
import { getEnv } from './basic'

const gTagId = apiConfig.googleAnalytics.id
window.dataLayer = window.dataLayer || [];
function gtag() {
  window.dataLayer.push(arguments);
}
gtag('js', new Date());

if(getEnv() !== 'prod') {
  gtag('create', gTagId, 'none');
}
gtag('config', gTagId);

export const googleTrackView = obj => {
  let customObj = {
    'page_title': obj.pageTitle,
    'page_location': window.location.href,
    'page_path': window.location.pathname,
  }
  if(obj.pageTitle === 'Course Details') {
    customObj.courseId = obj.courseId
  }
  gtag('config', gTagId, customObj);
}


export const googleTrackEvent = (eventName, options) => {
  gtag('event', eventName, options);
}
