import { combineReducers } from 'redux';
import authReducer from './Auth/reducer';
import alertReducer from './AlertMessages/reducer';
import recommendReducer from './RecommendCourse/reducer'
import profilePicReducer from './Profile/reducer'

export default combineReducers({
  authReducer,
  alertReducer,
  recommendReducer,
  profilePicReducer
});
